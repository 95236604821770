///
// Link list element
///

.ubbm-linklist {
  background-color: $gray-lighter;
  border-radius: rem(8);
  padding-bottom: rem(8);

  &__title {
    padding: rem(12) rem(16);
    margin: 0;
    @include emphasis-xs();
  }

  &__list {
    margin: 0;
    @include list-unstyled();
  }
}

.ubbm-linklist-item {
  padding: rem(8) rem(16);
  border-top: 1px solid $white;
  @include paragraph();

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $primary;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    .icon {
      fill: $primary;
    }
  }
}
