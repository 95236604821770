///
// Intro news overview
///

.ubbm-intro-news-overview {
  background-color: $gray-lighter;
  text-align: center;
  padding-top: rem(32);
  padding-bottom: rem(32);

  @include media-breakpoint-up(xxl) {
    padding-top: rem(24);
  }

  &__row {
    @include make-row();
  }

  &__inner {
    @include make-col-ready();

    @include make-col(12);

    @include media-breakpoint-up(xxl) {
      @include make-col(10);
      @include make-col-offset(1);
    }
  }

  &__title {
    margin-bottom: rem(8);
    @include heading-1();
  }

  &__lead {
    margin-bottom: rem(32);
    @include lead();
  }
}
