.ubbm-button {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.4%;
  text-align: center;
  text-decoration: none;
  padding: 16px 20px;
  display: inline-flex;
  align-items: center;

  &--link {
    background-color: transparent;
    color: $primary;
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 0;

    &:focus,
    &:hover {
      text-decoration: underline;
      color: $primary;
    }

    .icon {
      fill: $primary;
    }
  }

  &--primary {
    background-color: $primary;
    color: white;
    border-radius: 8px;
    border: 1px solid $primary;

    &:focus,
    &:hover {
      text-decoration: underline;
      color: white;
    }

    &:focus {
      outline: 4px solid $white;
      outline-offset: rem(0);
    }

    .icon {
      fill: $white;
    }
  }

  &--large {
    padding: 16px 20px 16px 20px;
  }
}
