///
// Treeview Browser
///

.treeview-navigation {
  @include media-breakpoint-down(lg) {
    margin-bottom: rem(16);
  }
}


.treeview-menu {
  margin: 0;
  padding: 0;
  list-style: none;

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: $gray-lighter;
  }

  > li {
    margin-bottom: rem(4);
    overflow: hidden;
    border-radius: rem(8);

    > a[role="treeitem"] {
      background-color: $primary;
      color: $white;
      font-weight: 500;

      .icon {
        fill: currentColor;
      }
    }

    > ul > li {
      border-bottom: 2px solid $white;
    }

    > ul > li > a[role="treeitem"] {
      font-weight: 500;
    }
  }

  li li span.label {
    // padding-left: 1em;
  }

  li li li span.label {
    padding-left: 2em;
  }

  a[role="treeitem"] {
    margin: 0;
    text-decoration: none;
    color: $body-color;
    border: none;
    display: block;
    padding: rem(12) rem(12);
    color: $body-color;
  }

  a[role="treeitem"][aria-expanded="false"] + [role="group"] {
    display: none;
  }

  a[role="treeitem"][aria-expanded="true"] + [role="group"] {
    display: block;
  }

  a[role="treeitem"] > span svg {
    transform: translate(0, 0);
  }

  a[role="treeitem"][aria-expanded="false"] > span svg {
    transform: rotate(270deg) translate(2px, 2px);
  }
}

.pa-document-browser__browser {
  background-color: $gray-lighter;
  border-radius: rem(8);
}

.block-pa-document-browser {
  padding-top: rem(80);
  padding-bottom: rem(80);
}

.pa-file-element {
  position: relative;
  padding: rem(16) rem(72) rem(16) rem(24);

  &:not(:first-child) {
    border-top: 1px solid $white;
  }

  .row {
    align-items: center;

    .column {
      @include make-col-ready();
      @include make-col(12);

      &:nth-child(1) {
        @include media-breakpoint-up(lg) {
          @include make-col(6.5);
        }
      }

      &:nth-child(2) {
        @include media-breakpoint-up(lg) {
          @include make-col(5.5);
        }
      }
    }
  }

  a {
    color: $body-color;
    text-decoration: none;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-breakpoint-down(lg) {
      margin-bottom: rem(8);
      display: block;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  .icon {
    position: absolute;
    top: rem(16);
    right: rem(24);
    fill: $primary;
  }

  &__meta {
    display: flex;
    @include media-breakpoint-up(lg) {

      justify-content: flex-end;
    }

    p {
      margin: 0;
      color: $gray;
      padding-right: rem(12);

      @include media-breakpoint-up(lg) {
        padding-left: rem(12);
      }
    }
  }
}

/* Remove default bullets */
ul, #myUL {
  list-style-type: none;
}

/* Remove margins and padding from the parent ul */
#myUL {
  margin: 0;
  padding: 0;
}

/* Style the caret/arrow */
.caret {
  cursor: pointer;
  user-select: none; /* Prevent text selection */
}

/* Create the caret/arrow with a unicode, and style it */
.caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

/* Rotate the caret/arrow icon when clicked on (using JavaScript) */
.caret-down::before {
  transform: rotate(90deg);
}

/* Hide the nested list */
.nested {
  display: none;
}

/* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */
.active {
  display: block;
}
