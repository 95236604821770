///
// Block Constructions Methods
// Block with construction methods teaser
///

.ubbm-block-construction-methods {
  background: linear-gradient(180deg, $gray-lighter 0%, rgba(241, 241, 246, 0) 100%);

  .ubbm-block__header {
    margin-bottom: rem(48);

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: space-between;
    }
  }
}

