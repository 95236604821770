///
// Global variables
///

// Color definitions

$ubbm-blue: #0047FF;

$white:     #FFFFFF;

$gray-lighter: #F1F1F6;
$gray-light:   #E2E2E5;
$gray:         #666666;
$gray-dark:    #222222;

// Overwrite bootstrap variables

$primary: $ubbm-blue;

//

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1340px
) !default;

// BS Dropdown variables

$dropdown-padding-x: 0;
$dropdown-padding-y: 0;
$dropdown-item-padding-y: 8px;
$dropdown-item-padding-x: 16px;
$dropdown-min-width: 248px;
$dropdown-border-raius: 0;
