///
// Block component
// Base styles for block component.
///

.ubbm-block {
  padding-top: rem(56);
  padding-bottom: rem(56);

  &__header {
    .container,
    .container-fluid {
      margin-bottom: rem(48);

      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &--default {
    background-color: $white;
  }

  &--bg-grey {
    background-color: $gray-lighter;
  }

  &__footer {
    .container {
      display: flex;
      justify-content: center;
      padding-top: rem(40);
      padding-bottom: rem(40);
    }
  }
}

.ubbm-block-heading {
  margin-bottom: 0;
  @include heading-2--large();
}
