///
// Intro - Project
// Intro component for project detail page.
///

.intro-project {
  padding-top: rem(40);
  padding-bottom: rem(24);

  @include media-breakpoint-up(xxl) {
    padding-top: rem(80);
  }

  &__inner {
    position: relative;
  }

  &__title-group {
    display: flex;
  }

  &__title {
    margin-bottom: 0;
    @include heading-1();

    @include media-breakpoint-down(xxl) {
      padding-left: rem(48);
    }
  }

  &__line-badge {
    width: rem(39);
    height: rem(24);
    position: absolute;
    top: rem(8);
    left: 0;

    @include media-breakpoint-up(xxl) {
      top: rem(16);
      left: rem(-100);
      width: rem(64);
      height: rem(40);
    }
  }

  &__subline {
    margin-bottom: rem(24);
    @include heading-3();
  }

  &__lead {
    margin-bottom: rem(24);
    @include heading-3();
  }
}
