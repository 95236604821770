.ubbm-filter-navigation {

}

.ubbm-filter-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: rem(16);

  @include list-unstyled();

  @include media-breakpoint-up(xxl) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__link {
    @include button-text();
    background-color: #FFFFFF;
    border: 1px solid $white;
    border-radius: rem(8);
    color: $gray;
    text-align: center;
    text-decoration: none;
    padding: rem(6) rem(16);
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      min-width: rem(240);
    }

    &--active {
      background-color: $primary;
      color: white;
      border: 1px solid $primary;

      &:hover,
      &:focus {
        color: $white;
      }
    }
  }
}
