@mixin heading-1() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 700;
  font-size: rem(32);
  line-height: 1.3;
  letter-spacing: -0.01em;

  @include media-breakpoint-up(xxl) {
    font-size: rem(56);
  }
}

@mixin heading-2() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 500;
  font-size: rem(24);
  line-height: 1.3;
  letter-spacing: -0.04%;

  @include media-breakpoint-up(xxl) {
    font-size: rem(24);
  }
}

@mixin heading-2--large() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 500;
  font-size: rem(32);
  line-height: 1.3;
  letter-spacing: -0.04%;
}

@mixin heading-3() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 500;
  font-size: rem(24);
  line-height: 1.3;
  letter-spacing: -0.04em;

  @include media-breakpoint-up(xxl) {
    font-size: rem(24);
  }
}

@mixin heading-4() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 500;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.004em;
}

@mixin lead() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 500;
  font-size: rem(18);
  line-height: 1.5;
  letter-spacing: -0.004em;

  @include media-breakpoint-up(xxl) {
    font-size: rem(24);
  }
}

@mixin paragraph() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 400;
  font-size: rem(18);
  line-height: 1.6;
  letter-spacing: 0.01em;
}

@mixin paragraph--small() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 400;
  font-size: rem(16);
  line-height: 1.6;
  letter-spacing: 0.01em;
}

@mixin paragraph--large() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 400;
  font-size: rem(18);
  line-height: 1.6;
  letter-spacing: 0.01em;
}

@mixin paragraph--smaller() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 400;
  font-size: rem(12);
  line-height: 1.5;
  letter-spacing: 0.01em;
}

@mixin paragraph--highlight() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 500;
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: 0.01em;
}

@mixin emphasis-xs {
  font-family: var(--bs-font-sans-serif);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: -0.004em;

  @include media-breakpoint-up(xxl) {
    font-size: 18px;
  }
}

@mixin button-text {
  font-family: var(--bs-font-sans-serif);
  font-size: rem(18);
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.004em;
}

