///
// Global base layout styles
///

body {
  max-width: 100%;
  overflow-x: hidden;
}

///
// Column sets
///

.row {

  &--6-6 {
    .column {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-down(lg) {
        margin-bottom: rem(40);
      }

      &:nth-child(1) {
        @include media-breakpoint-up(lg) {
          @include make-col-offset(1);
          @include make-col(5);
        }
      }

      &:nth-child(2) {
        @include media-breakpoint-up(lg) {
          @include make-col(5);
        }
      }
    }
  }
}
