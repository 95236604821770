///
// Teaser news
// Teaser element for news.
///

.ubbm-teaser-news {
  display: flex;
  flex-direction: column;
  background-color: $gray-lighter;
  border-radius: rem(8);
  overflow: hidden;
  position: relative;
  height: 100%;

  &__image {
    overflow: hidden;
    order: 1;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__body {
    order: 2;
    padding: rem(16) rem(16) 0;
    flex: 1;
  }

  &__footer {
    order: 3;
    padding: 0 rem(16) rem(16);
  }

  &__heading {
    margin-bottom: rem(8);
    @include heading-3();

    a {
      text-decoration: none;
      color: $gray-dark;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom:0;
        left: 0;
        display: block;
      }
    }
  }

  &__date {
    position: absolute;
    top: rem(16);
    left: rem(16);
    background-color: $white;
    padding: 0 rem(8);
    border-radius: 9999px;
    @include paragraph--smaller();
  }

  &__more {
    color: $primary;
    font-family: var(--bs-font-sans-serif);
    text-align: right;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
    @include button-text();

    .icon {
      fill: $primary;
    }
  }

  &--default {
    padding-top: rem(64);
  }
}

///
// Variant, if in block variant
///

.ubbm-block {
  &--bg-grey {
    .ubbm-teaser-news {
      background-color: $white;
    }
  }
}
