///
// Global focus styles
///

// Set focus style like in the old times
*:focus-visible {
  z-index: 1;
  outline: 2px solid $primary;
  outline-offset: rem(4);
}
