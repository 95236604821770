///
// Job vacancy element
///

.job-vacancy__list {
  @include list-unstyled();
}

.job-vacancy {
  border-bottom: 1px solid $gray-light;
  padding: rem(24);
  position: relative;

  @include media-breakpoint-up(xl) {
    margin-left: rem(-24);
    margin-right: rem(-24);
  }

  &__row {
    @include make-row();
  }

  &__column {
    @include make-col-ready();
    @include make-col(12);
  }

  &__column:nth-child(1) {
    @include media-breakpoint-up(xl) {
      @include make-col(4.8);
    }
  }

  &__column:nth-child(2) {
    @include media-breakpoint-up(xl) {
      @include make-col(7.2);
    }
  }

  &__title {
    max-width: 80%;
    margin-bottom: rem(16);

    @include media-breakpoint-up(xl) {
      margin-bottom: rem(24);
    }

    @include heading-2--large();
  }

  &__meta {
    display: flex;
    align-items: center;
    color: $gray;
    margin-bottom: rem(8);

    @include media-breakpoint-down(xl) {
      margin-bottom: rem(16);
    }


    p {
      margin: 0;
      @include paragraph();
    }

    .icon {
      width: rem(24);
      height: rem(24);
      fill: gray;
    }
  }

  &__text {
    p {
      @include paragraph();
    }
  }

  &__more {
    @include media-breakpoint-up(xl) {
      position: absolute;
      top: rem(24);
      right: 0;
    }
  }
}
