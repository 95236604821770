///
// Project navigation
///

.ubbm-project-navigation {
  border-top: 1px solid $gray-lighter;
  padding-top: rem(24);
  padding-bottom: rem(24);
}

.ubbm-project-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__previous,
  &__next {
    a {
      color: $primary;
      text-decoration: none;
      display: flex;
      align-items: center;
      @include emphasis-xs();

      .icon {
        fill: $primary;
      }

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
