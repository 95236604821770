///
// U-Bahn Bauen München
// Frontend components
// 2023
///

// Set global variables
// Overwrite bootstrap variables before bootstrap import

@import "variables";

// Import bootstrap core

@import "~bootstrap";

// Import splide slider
@import '@splidejs/splide/css';
@import '@splidejs/splide/css/core';


// Overwrite CSS properties

:root {
  --bs-font-sans-serif: "Roboto", sans-serif;
  --bs-body-color: #222222; // @TODO Check, why variable doesn't work here...
  --bs-dropdown-border-radius: 0;
}


///
// Import base, elements and components
///

// Helper functions and mixins

@import "abstracts/functions/functions";
@import "abstracts/mixins/mixins";

// Base styles

@import "base/focus";
@import "base/fonts";
@import "base/icons";
@import "base/typography";
@import "base/layout";

// Elements

@import "elements/buttons";
@import "elements/content";
@import "elements/linklist";
@import "elements/teaser/teaser-news";
@import "elements/teaser/teaser-construction-method";
@import "elements/filter-navigation";
@import "elements/publication";
@import "elements/job-vacancy";

// Components

@import "components/layout/header";
@import "components/layout/footer";

@import "components/breadcrumb";

@import "components/stage";

@import "components/intros/intro-news-overview";
@import "components/intros/intro-news";
@import "components/intros/intro-project";

@import "components/project-navigation";

@import "components/block-contact";

@import "components/block";
@import "components/page-title";
@import "components/block-table-of-content";
@import "components/block-project-data";
@import "components/block-construction-methods";
@import "components/block-publications";
@import "components/block-overview-map";
@import "components/block-station";
@import "components/block-embed";
@import "components/block-gallery";
@import "components/teaser-lists/teaserlist";
@import "components/treeview-browser";






.ubbm-image {
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
}

.ubbm-tag {
  background-color: $primary;
  color: $white;
  display: flex;
  padding: rem(4) rem(8);
  border-radius: 99999px;
  font-size: 14px;
  line-height: 1;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $white;
    text-decoration: underline;
  }
}


.lead {
  @include lead();
}


.ubbm-content-highlight {
  background-color: $gray-lighter;
  padding-top: rem(64);
  padding-bottom: rem(64);

  &__intro {
    margin-bottom: rem(40);
  }

  &__intro-heading {
    margin-bottom: rem(16);
    @include heading-2();
  }

  &__intro-text {
    p {
      @include lead();
    }
  }
}

.ubbm-contact-block {
  &__title {
    width: 100%;
    @include heading-3();
  }

  &__information {

    @include media-breakpoint-up(xl) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__address {
    @include paragraph();
  }

  &__contact {

  }

  &__contact-list {
    @include list-unstyled();
    @include paragraph();

    li {
      // margin-bottom: rem(4);
      display: flex;
      align-items: center;
    }

    .icon {
      fill: $primary;
    }
  }
}

.ubbm-images {
  padding-top: rem(40);
  padding-bottom: rem(40);
}

.ubbm-image {
  &__caption {
    padding-top: rem(16);

    p {
      @include paragraph--small();
    }
  }
}

