///
// Block gallery
///


.ubbm-block-gallery {
  padding-top: rem(40);
  padding-bottom: rem(40);
}

.image-gallery {
  position: relative;

  .previous-button,
  .next-button {
    width: rem(48);
    height: rem(48);
    display: block;
    background-color: $primary;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $primary;
    z-index: 9;

    position: absolute;
    top: rem(244.5);
    margin-top: rem(-24);

    .icon {
      fill: $white;
    }
  }

  .previous-button {
    left: rem(-72);
  }

  .next-button {
    right: rem(-72);
  }

  img {
    margin-bottom: rem(40);
  }

  .splide__pagination {
    top: rem(489);
    left: 0;
    right: 0;
    bottom: auto;
    padding-top: rem(16);
  }

  .splide__pagination__page.is-active {
    background-color: $primary;
    border-color: $primary;
    opacity: 1;
  }
}
