///
// Overview map
///

.uncollapsed {
  display: none;
}

.collapsed {
  display: block;
}


.ubbm-block-overview-map {
  background-color: $gray-lighter;
  padding-top: rem(0) !important;
  padding-bottom: rem(0) !important;
}

.ubbm-overview-map__overview,
.ubbm-overview-map__detail {
  transition: all 1.5s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ubbm-overview-map__detail {
  opacity: 0;
  // display: none;

  &.show {
    // display: block;
    opacity: 1;
  }
}

.ubbm-overview-map__overview {
  z-index: 1;
}


.ubbm-overview-map {
  overflow: hidden;
  height: 720px;
  background-color: #F1F1F6;
  position: relative;

  &__detail {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  &__image {
    position: relative;
    z-index: 0;
  }

  &__menu {
    padding-top: rem(48);
    padding-bottom: rem(48);
  }

  &__info {
    padding-top: rem(48);
  }

  &__accordion {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    bottom: rem(48);
  }

  .map-header {
    position: relative;
    top: 0;
    right: 0;
    margin-bottom: rem(8);

    button {
      border: 1px solid transparent;
      border-radius: rem(8);
      padding: rem(8) rem(16);
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      &[aria-expanded="false"] {
        background-color: $white;
      }

      &[aria-expanded="true"] {
        background-color: $primary;
        color: $white;
      }
    }

    &__info {
      text-align: left;
    }

    &__line {
      margin-right: rem(8);

      svg {
        width: rem(40);
        height: rem(40);
      }
    }

    &__title {
      margin-bottom: rem(0);
      @include paragraph();
    }

    &__status {
      display: flex;
      background-color: #1F996D;
      color: $white;
      border-radius: 99999px;
      font-size: rem(12);
      line-height: 1.5;
      padding: rem(2) rem(8);
      font-weight: 400;
      margin-bottom: rem(0);
    }
  }

  .map-content {
    position: absolute;
    top: 0;
    right: 0;
  }

  .map-body {
    background-color: white;
    width: 280px;
    position: absolute;
    top: 0;
    right: 0;
    max-height: 180px;
    overflow-y: scroll;
    overflow-x: hidden;
    width:300px;
  }

  .map-teaser {
    background-color: $white;
    align-items: center;
    border-radius: rem(8);
    overflow: hidden;

    &__body {
      overflow-y: scroll;
      overflow-x: hidden;
      max-height: rem(552);

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: $white;
        border: 1px solid $white;
        padding: rem(2);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: rem(8);
        background-color: $gray-light;
        border: 1px solid $gray-light;
      }
    }

    &__line-badge {
      width: rem(40);
      height: rem(40);
      margin-right: rem(8);
    }

    &__title {
      color: $white;
      margin-bottom: 0;
    }

    &__header {
      background-color: $primary;
      padding: rem(16) rem(24);
      display: flex;
    }

    &__info,
    &__stations,
    &__details {
      border: 1px solid $gray-lighter;
      padding: rem(16);
    }
  }

  .collapse {
    animation-name: slideInRight;
    background-color: transparent;
  }

  .collapsing {
    transition: none;
  }

  .show {
    animation-duration: 1s;
    animation-fill-mode: both;
  }

}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

