///
// Teaser lists
///

.ubbm-teaser-list {
  $self: &;

  &__row {
    @include make-row();
    @include list-unstyled();
  }

  &__item {
    margin-bottom: rem(24);
    @include make-col-ready();
    @include make-col(12);
  }

  &--4-4-4 {
    #{ $self }__item {
      @include media-breakpoint-up(xxl) {
        @include make-col(4);
      }
    }
  }

  &--3-3-3-3 {
    #{ $self }__item {
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(4);
      }

      @include media-breakpoint-up(xxl) {
        @include make-col(3);
      }
    }
  }
}
