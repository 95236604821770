///
// Construction method teaser
///

.ubbm-construction-teaser {
  position: relative;
  margin-bottom: rem(24);
  height: calc(100% - rem(24));
  display: flex;
  flex-direction: column;

  &__header {
    background-color: $primary;
    color: white;
    margin: 0;
    border-top-left-radius: rem(8);
    border-top-right-radius: rem(8);
    padding: rem(12) rem(24);
    display: flex;
    align-items: center;
  }

  &__body {
    background-color: $gray-lighter;
    padding: rem(16) rem(40) rem(24) rem(40);
    border-bottom-left-radius: rem(8);
    border-bottom-right-radius: rem(8);
    flex: 1;
  }

  &__info {
    order: 2;
  }

  &__subtitle {
    margin: 0
  }

  &__pictogram {
    order: 1;
    margin-right: rem(16);
  }

  &__more {
    order: 3;
    margin: 0;
    margin-left: auto;

    .icon {
      fill: white;
    }
  }

  &__title {
    margin: 0;

    a {
      color: white;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        content: "";
      }
    }
  }

  &__text {
    margin: 0;
  }
}
