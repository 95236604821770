
.ubbm-embed {
  padding-top: rem(56);
  padding-bottom: rem(56);

  .content {
    margin-bottom: rem(28);
  }
}

.oembed-element {
  &__usercentrics {
    background-color: #ededed;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: rem(48);

    .h3 {
      @include heading-3();
      margin-top: 1.5em;
      margin-bottom: 0.5em;
    }

    p {
      @include paragraph();
      margin-bottom: 1.5em;
    }

    .button {
      margin: 0;
    }
  }

  &__embed {
    iframe {
      aspect-ratio: 16/9;
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__bypass {
    margin-top: rem(16);
    text-align: center;
    @include paragraph();
  }
}
