.page__footer {
  background-color: $primary;
  color: white;
  padding-top: rem(23);
  padding-bottom: rem(23);

  @include media-breakpoint-up(lg) {
    padding-top: rem(40);
    padding-bottom: rem(40);
  }

  .ubbm-brand {
    @include media-breakpoint-down(lg) {
      margin-bottom: rem(40);
    }

    @include media-breakpoint-up(lg) {
      margin-top: rem(8);
    }

    svg path {
      fill: $white;
    }
  }
}

.ubbm-service-menu {
  @include make-row();
  @include list-unstyled();

  @include media-breakpoint-down(lg) {
    margin-bottom: rem(32);
  }

  &__item {
    margin-bottom: rem(16);
    @include make-col-ready();
    @include make-col(6);

    @include media-breakpoint-up(lg) {
      margin-bottom: rem(8);
      @include make-col(4);
    }
  }

  &__link {
    color: $white;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
      color: $white;
    }
  }
}
