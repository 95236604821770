.ubbm-project-data {
  background: linear-gradient(180deg, $gray-lighter 0%, rgba(241, 241, 246, 0) 100%);
  padding-top: 48px;
  padding-bottom: 48px;

  &__facts {
    margin-bottom: 24px;
  }

  &__title {
    margin-bottom: rem(16);
    @include heading-2--large();
  }

  &__subtitle {
    margin: 0;
    @include heading-3();
  }

  &__info {
    margin-bottom: rem(24);
    @include paragraph();
  }
}


.ubbm-data-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 16px;

  @include media-breakpoint-up(xl) {
    column-gap: 24px;
  }
}

.ubbm-data-item {
  background-color: $primary;
  color: white;
  text-align: center;
  margin-bottom: 0;
  padding: 16px;
  margin-bottom: 24px;
  border-radius: 8px;

  .ubbm-data-item__label,
  .ubbm-data-item__value {
    display: block;
  }

  .ubbm-data-item__value {
    font-size: rem(32);
    line-height: 1.3;

    @include media-breakpoint-up(xl) {
      font-size: 48px;
    }
  }
}

.ubbm-milestones {
  @include media-breakpoint-down(xl) {
    position: relative;
    padding-left: rem(20);
  }

  &__timeline {
    background: linear-gradient(90deg, #336CFF 0%, #00487B 100%);
    border-radius: 8px;
    // display: flex;

    @include media-breakpoint-down(xl) {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: rem(16);
    }

    @include media-breakpoint-up(xl) {
      width: 100%;
      height: 16px;
      border-radius: 8px;
      display: block;
    }
  }

  &__milestones {
    display: flex;
    flex-direction: row;

    @include media-breakpoint-down(xl) {
      flex-direction: column;
    }

    @include media-breakpoint-up(xl) {
      flex-wrap: nowrap;
      gap: 24px;
    }
  }

  &__milestone {
    position: relative;

    @include media-breakpoint-down(xl) {
      padding-left: rem(4);
      margin-bottom: rem(24);
    }

    @include media-breakpoint-up(xl) {
      margin-top: rem(-24);
      padding: rem(12) rem(4);
    }

    h4 {
      margin-bottom: 4px;
      @include heading-4();
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      background-color: white;
      display: block;
      border-radius: 50%;
      margin-bottom: 8px;

      @include media-breakpoint-down(xl) {
        position: absolute;
        top: rem(8);
        left: rem(-16);
      }
    }
  }
}
