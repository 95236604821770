.content {
  h2 {
    margin-bottom: 0.75em;
    @include heading-2();
  }

  h3 {
    @include heading-3();
  }

  p {
    margin-bottom: 1.5em;
    @include paragraph();
  }

  ul,
  ol {
    margin-bottom: 1.5em;
    margin-top: 1.5em;
    @include paragraph();
  }

  li {
    margin-bottom: 1.5em;
  }

  .highlight {
    @include paragraph--highlight();
  }
}

.ubbm-content {
  padding-top: rem(40);
  padding-bottom: rem(40);
}
