.ubbm-breadcrumb-component {
  background-color: $gray-lighter;

  @include media-breakpoint-up(xl) {
    padding: 0 rem(8);
  }

  .container,
  .container-fluid {
    display: flex;
  }
}

// Set negative margin, if breadcrumb is in breadcrumb container
.ubbm-breadcrumb-component {
  .ubbm-breadcrumb {
    margin-left: -(rem(9));
  }
}

.ubbm-breadcrumb {
  padding: rem(16) 0;
  font-size: rem(14);
  line-height: 1.75;

  @include media-breakpoint-down(xl) {
    display: none;
  }

  @include media-breakpoint-up(xl) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__list {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__list-item {
    display: flex;
    align-items: center;
  }

  &__list-item-current,
  &__list-item-link {
    padding: 0 rem(8);
  }

  &__list-item-link {
    color: $body-color;
  }

  &__list-item-current {
    color: $body-color;
  }

  .icon-home {
    width: rem(20);
    height: rem(20);
    color: $body-color;
    fill: currentColor;
  }

  .icon-separator {
    width: rem(9);
    height: rem(9);
    color: $body-color;
    fill: currentColor;
  }
}

.ubbm-breadcrumb-go-back {
  display: flex;
  align-items: center;
  padding: rem(8) 0;

  &__link {
    padding: 0 0 0 rem(6);
    font-family: "Roboto Condensed", sans-serif;
    font-size: rem(14);
    line-height: 1.6;
    color: $body-color;
  }

  .icon {
    width: rem(9);
    height: rem(9);
    color: $primary;
    fill: currentColor;
  }

  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.ubbm-breadcrumb-image {
  position: relative;
  padding: rem(8) 0;
  margin-left: auto;

  @include media-breakpoint-up(xl) {
    padding: rem(11) 0 rem(10) 0;
  }

  &__label {
    margin-right: rem(8);
    font-size: rem(16);
    font-weight: 700;

    @include media-breakpoint-up(xl) {
      padding-left: rem(32);

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 1px;
        content: "";
        background-color: $primary;
      }
    }
  }

  img {
    max-height: rem(24);
  }
}
