///
// Block page title and image
///

.ubbm-page-title {
  background-color: $gray-lighter;
  text-align: center;
  padding-top: rem(48);
  padding-bottom: rem(32);

  &__title {
    margin-bottom: rem(16);
    @include heading-1();
  }
}

.ubbm-page-image {
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
