.ubbm-badge {
  display: inline-block;
  width: 2.375rem;
  height: 1.5rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;

  &--before {
    margin-right: 8px;
  }

  &--after {
    margin-left: 8px;
  }
}

.ubbm-badgeu-linie-badge--u1 {
  width: 1.5771484375em;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;

  &--before {
    margin-right: 4px;
  }

  &--after {
    margin-left: 4px;
  }
}
