///
// Page header with main navigation
///

.page__header {
  padding-top: rem(16);
  padding-bottom: rem(0);

  @include media-breakpoint-up(xxl) {
    padding-top: rem(24);
    padding-bottom: rem(24);
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    @extend .container;

    @include media-breakpoint-down(xxl) {
      padding: 0;
    }
  }

  .ubbm-brand,
  .ubbm-lhm-brand {
    @include media-breakpoint-down(xxl) {
      margin-bottom: rem(16);
    }

    svg path {
      @include media-breakpoint-down(xxl) {
        height: rem(30);
        width: auto;
      }
    }
  }

  .ubbm-brand {
    @include media-breakpoint-down(xxl) {
      margin-right: auto;
    }
  }

  .ubbm-lhm-brand {
    @include media-breakpoint-down(xxl) {
      margin-right: rem(16);
    }
  }

  .navbar-toggler {
    border: 0;
    border-radius: 0;

    .icon {
      fill: $primary;
      width: rem(32);
      height: rem(32);
    }

    @include media-breakpoint-down(xxl) {
      margin-right: rem(16);
      margin-bottom: rem(16);
      margin-left: rem(16);
    }
  }

  .navbar-collapse {
    flex-grow: 0;

    @include media-breakpoint-down(xxl) {
      order: 1;
      padding-top: 0;
      padding-bottom: 0;
    }

    @include media-breakpoint-up(xxl) {
      padding-top: rem(24);
    }
  }

  .navbar-nav {
    @include media-breakpoint-down(xxl) {
      border-top: 1px solid $gray-lighter;
    }

    .nav-item {
      @include media-breakpoint-down(xxl) {
        border-bottom: 1px solid $gray-lighter;
      }

      @include media-breakpoint-up(xxl) {
        margin-left: 12px;
        margin-right: 12px;
      }
    }

    .nav-link {
      font-size: rem(18);
      font-weight: 500;
      line-height: 1.5;
      color: $gray-dark;
      text-decoration: none;
      position: relative;

      @include media-breakpoint-down(xxl) {
        padding-left: rem(24);
        padding-right: rem(24);
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      @include media-breakpoint-up(xxl) {
        border-radius: rem(8);
        padding-left: rem(16);
        padding-right: rem(16);
      }

      &::after {
        @include media-breakpoint-up(xxl) {
          content: '';
          position: absolute;
          bottom: 2px;
          border-bottom: 0.125em solid $body-color;
          transition: all 0.3s;
          left: 50%;
          right: 50%;
        }
      }

      &:hover::after {
        @include media-breakpoint-up(xxl) {
          left: 0;
          right: 0;
        }
      }

      &:hover,
      &:focus {
        background-color: $white;
        color: $body-color;
        text-decoration: none;
      }

      &:focus {
        @include media-breakpoint-up(xxl) {
          background-color: $primary;
          color: white;

          .icon {
            fill: white;
          }
        }
      }

      &:focus:hover {
        &::after {
          content: none;
        }
      }

      .icon {
        transition: all 0.3s ease;
      }

      &[aria-expanded="false"] {
        .icon {
          @include media-breakpoint-down(xl) {
            transform: rotate(-90deg);
          }
        }
      }

      &[aria-expanded="true"] {
        @include media-breakpoint-down(xxl) {
          color: $primary;
        }

        .icon {
          @include media-breakpoint-down(xxl) {
            fill: $primary;
          }
        }
      }
    }
  }

  &--transparent {
    background: linear-gradient(180deg, rgba(33, 33, 33, 0.5) 50.51%, rgba(33, 33, 33, 0) 100%);

    .ubbm-brand svg path {
      fill: white;
    }

    .ubbm-lhm-brand svg path {
      fill: white;
    }

    .navbar-nav {
      .nav-link {
        color: white;

        &:focus {
          background-color: $primary;
          color: white;
        }
      }
    }
  }

  &--default {
    background-color: white;
    transition: all .75s ease;

    .ubbm-brand svg path {
      fill: $primary;
      transition: all .3s ease;
    }

    .ubbm-lhm-brand svg path {
      fill: $black;
      transition: all .3s ease;
    }
  }
}

.dropdown-menu {
  overflow: hidden;

  @include media-breakpoint-down(xxl) {
    border: 0;
    padding-bottom: rem(24);
  }

  @include media-breakpoint-up(xxl) {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(8px);
  }

  .menu-item {
    border-radius: 0;
  }
}


.dropdown-item {
  font-size: rem(18);
  font-weight: 500;
  line-height: 1.5;
  color: $gray-dark;
  text-decoration: none;
  position: relative;

  @include media-breakpoint-down(xxl) {
    padding-left: rem(24);
  }

  @include media-breakpoint-up(xxl) {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    border-bottom: 1px solid #BCBCBF;
    display: flex;
    align-items: center;
  }

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

.dropdown-menu > .dropdown-item:first-child,
.dropdown-menu > li:first-child .dropdown-item,
.dropdown-menu > .dropdown-item:last-child,
.dropdown-menu > li:last-child .dropdown-item {
  border-radius: 0;
}

.dropdown-menu > .dropdown-item:last-child,
.dropdown-menu > li:last-child .dropdown-item {
  border: 0;
}

.dropdown-toggle {
  .icon {
    margin-right: rem(-8);
  }

  &::after {
    content: none;
    border: 0;
  }
}

