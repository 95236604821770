///
// (Hero) Stage
// Stage with image and text overlay.
///

.ubbm-stage {
  position: relative;

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.3);
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: rem(554);
  }

  &__title {
    color: white;
    text-shadow: 0 0 rem(16) rgba(0, 0, 0, 0.4);
    margin-bottom: rem(48);
    @include heading-1();
  }
}
