///
// Intro news (detail view)
///

.ubbm-intro-news {
  background-color: $gray-lighter;
  padding-bottom: rem(24);

  &__back {
    padding-top: rem(24);
    padding-bottom: rem(24);
  }

  &__row {
    @include make-row();
  }

  &__inner {
    @include make-col-ready();
    @include make-col(10);
    @include make-col-offset(1);

    @include media-breakpoint-up(xl) {
      @include make-col(8);
      @include make-col-offset(2);
    }
  }

  &__title {
    margin-bottom: rem(24);
    @include heading-1();
  }

  &__meta {
    display: flex;
    align-items: center;
    gap: rem(24);
    margin-bottom: rem(24);
  }

  &__date {
    margin: 0;
    @include paragraph--small();
  }

  &__lead {
    @include lead();
  }
}
