///
// Publication block
// Block with publication list
///

.ubbm-block-publications {
  background: linear-gradient(180deg, #F1F1F6 0%, rgba(241, 241, 246, 0) 100%);
}

.ubbm-publication-list {
  @include list-unstyled();
}

