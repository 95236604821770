///
// Block contact
///

.ubbm-content-highlight {
  background-color: $gray-lighter;
  padding-top: rem(64);
  padding-bottom: rem(64);

  .content {
    margin-bottom: rem(40);

    h2 {
      @include heading-2();
    }

    p {
      @include lead();
    }
  }
}

.contact {
  &__address {
    p {
      @include emphasis-xs();
    }
  }
}
