///
// Table of Content
// Component for table of content.


.block-table-of-content {
  position: relative;
  overflow: hidden;

  &__image {
    width: 100%;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__navigation {
    @include media-breakpoint-up(xxl) {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      height: 100%;
    }

    .container {
      height: 100%;
      display: flex;
      flex-grow: 1;
    }

    .row {
      width: 100%;
    }
  }

  .ubbm-table-of-content {
    height: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    position: relative;
    z-index: 2;
    padding: 0;

    @include media-breakpoint-down(xxl) {
      transform: translateY(rem(-49));
    }

    @include media-breakpoint-up(xxl) {
      background-color: $gray-lighter;
      padding: rem(48) rem(24) rem(24) rem(24);
      margin-left: rem(12);
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      z-index: -1;

      @include media-breakpoint-up(xxl) {
        content: "";
        background-color: $gray-lighter;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 9999px;
        z-index: -1;
      }
    }

    &__inner {
      background-color: $gray-lighter;
      border-radius: rem(8);
      overflow: hidden;

      @include media-breakpoint-up(xxl) {
        background-color: $white;
      }
    }

    &__title {
      background-color: $primary;
      color: white;
      padding: rem(12) rem(16);
      margin: 0;
      @include heading-4();
    }

    &__list {
      @include list-unstyled();
      margin: 0;
      padding: rem(16);
    }

    &__list-item {
      margin-bottom: rem(8);
      display: flex;
      @include emphasis-xs();

      a {
        text-decoration: none;
      }

      .icon {
        fill: $primary;
      }
    }
  }
}
