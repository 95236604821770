///
// Publication element
///

.ubbm-publication {
  background-color: $primary;
  color: white;
  border-radius: rem(8);
  display: flex;
  position: relative;
  padding: rem(10) rem(24);
  margin-bottom: rem(8);

  &__info {
    order: 2;

    @include media-breakpoint-up(lg) {
      padding-top: rem(10);
    }
  }

  &__thumbnail {
    order: 1;
    margin-right: rem(16);
  }

  &__icon {
    order: 3;
    position: absolute;
    top: 0;
    right: rem(24);
    bottom: 0;
    fill: $white;
    display: flex;
    align-items: center;
    height: 100%;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__title {
    margin: 0;
    @include heading-3();

    a {
      color: white;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    a:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }

  &__subline {
    @include paragraph();
  }
}
