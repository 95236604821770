.ubbm-block-station {
  background: linear-gradient(180deg, $gray-lighter 0%, rgba(241, 241, 246, 0) 100%);

  @include media-breakpoint-up(xl) {
    padding-top: rem(64);
  }

  .ubbm-block-heading {
    margin-bottom: 0;
    @include heading-2--large();
  }

  .lead {
    margin: 0;
    @include lead();
  }

  &__header {
    margin-bottom: rem(40);
  }
}

///
// Station info element
///

.station-info {
  position: relative;
  margin-bottom: rem(24);
  padding-left: rem(48);
  padding-right: rem(24);
  padding-top: rem(6);

  &__icon {
    background-color: $primary;
    border-radius: 50%;
    width: rem(40);
    height: rem(40);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;

    .icon {
      width: rem(24);
      height: rem(24);
      fill: $white;
    }
  }

  &__title {
    margin-bottom: 0.5em;
    @include heading-3();
  }

  dl {
    display: flex;
    flex-wrap: wrap;

    dt {
      width: 40%;
    }

    dd {
      width: 60%;
      padding-left: rem(24);
    }
  }
}
